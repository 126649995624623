@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

// themes - our custom or/and out of the box themes
@import "themes";

// framework component themes (styles tied to theme variables)
@import "~@nebular/theme/styles/globals";
@import "~@nebular/auth/styles/globals";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/grid";

// loading progress bar theme
@import "./pace.theme";

@import "./layout";
@import "./overrides";

$bg-color: var(--property-color, #5E0D8B ); // Default value if sessionStorage value is not set

$white-color: #ffffff;
$primary: #5E0D8B;

// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  //@include ngx-layout();
  // loading progress bar
  //@include ngx-pace-theme();

  //@include nb-overrides();

  /* Importing Bootstrap SCSS file. */
  //@import '~bootstrap/scss/bootstrap';
  body {
    font-family: "Open Sans", sans-serif;
  }
}

.homelogin nb-layout-column nb-card {
  background-color: transparent !important;
  border: none;
  height: 100% !important;
}
.homelogin .columns {
  background-image: url(../../../assets/images/login-bg.png);
  background-size: cover;
  background-repeat:no-repeat;
}
.login {
  background: rgba(17, 33, 53, 0.8);
  padding: 10px;
}
.login label {
  color: #fff !important;
}

.loginBtn {
  background-color: $primary !important;
  border-color: $primary !important;
}

.continueBtn {
  background-color: #5E0D8B !important;
  border-color: #5E0D8B !important;
  font-size: 21px;
  width: 200px;
  color: white;
  border-radius: 7px;
  margin-top: 7px;
  margin-left: -62px;
}
.forgot-password {
  color: #fff !important;
}
.nb-theme-default nb-layout-header nav {
  background-color: $bg-color !important;
}
.nb-theme-default nb-sidebar {
  background-color: #f4f4f4 !important;
}
.sidebar-toggle {
  color: #FFFFFF !important;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.container-body {
  border: 1px solid #ddd;
  background: #fff;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  padding: 20px 20px 20px 20px;
}
.nb-theme-default
  nb-layout
  .layout
  .layout-container
  .content
  .columns
  nb-layout-column {
  padding: 12px;
}
.header-title {
  // display: flex;
  // justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding-bottom: 12px;
  margin-bottom: 12px;
  align-items: center;
}
.header-title h2 {
  color: #333333;
  font-size: 30px !important;
  margin: 0 !important;
}
.search-container {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  border: 1px solid #ddd;
  padding: 12px;
  border-top: 3px solid $bg-color;
  border-radius: 4px !important;
}
.search-container h3 {
  color: #333333;
  font-size: 20px !important;
  margin: 0 !important;
}
.mat-header-row {
  background-color: #fff;
}
.mat-sort-header-content,
.mat-column-actions {
  color: #3C8DCA !important;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 14.06px;
  text-align: left !important;
}
.btn {
  background-color: $bg-color !important;
  color: $white-color !important;
  border-color: transparent;
}
.mat-header-cell {
  color: #3C8DCA !important;
  padding: 5px !important;
}
.nb-theme-default nb-menu .menu-item {
  border-bottom: 1px solid #e3e3e3;
}
.nb-theme-default nb-menu .menu-item a {
  color: #222b45;
}
.nb-theme-default nb-menu .menu-item a.active {
  color: #fff;
  background: $bg-color !important;
}
.nb-theme-default nb-menu .menu-item a.active .menu-icon {
  filter: contrast(100);
}
.nb-theme-default nb-menu .menu-item a:hover {
  color: #fff;
  background: $bg-color !important;
}
.nb-theme-default nb-menu .menu-item a:hover .menu-icon {
  filter: contrast(100);
}
.nb-theme-default .menu-sidebar .scrollable {
  padding-top: 0 !important;
}
.mat-elevation-z8 {
  box-shadow: none !important;
  border: 1px solid #ddd;
}
.nb-theme-default nb-user.size-medium .user-name {
  color: $white-color !important;
}
.nb-theme-default nb-user.shape-round .user-picture {
  color: $white-color !important;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  border-radius: 5px !important;
  background-color: #f5f5f5 !important;
}

::-webkit-scrollbar {
  width: 12px !important;
  background-color: #555 !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: rgb(117, 117, 117) !important;
}

.cancel{
  border-style: solid;
  border-color: transparent;
  color: #FFFFFF !important;
  background-color: #767676 !important;
}
// .nb-theme-default nb-checkbox.status-basic .text{
//   color: #fff !important;
// }

//For spinner
// .nb-theme-default nb-spinner.size-medium {
//   height: 15% !important;
//   z-index: 999999;
// }

.nb-theme-default nb-sidebar.compacted nb-menu .menu-item a.active::before{
  background: $bg-color;
}
.nb-theme-default nb-sidebar.compacted nb-menu {
  .menu-item .menu-icon {
    margin: 0;
  }
}

.selected-property{
  padding: 20px;
}

.nb-theme-default nb-checkbox.status-basic .text {
  color: #fff;
}

.border-bottom {
  border-bottom: 1px solid #000 !important;
}

.actionColumn{
  color: #3C8DCA !important;
  text-align: center !important;
}

.mat-pseudo-checkbox-checked {
  background: $bg-color !important;
}

.cart-remark-label {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: left;
}

.cart-remark-label-id{
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  text-align: left;
}

.sendMail{
  background-color:white !important;
  border-color: #8F8F8F !important;
  width: 370px !important;
}



// Top Search Filter Box Start
.search-filters {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  border: 1px solid #ddd;
  padding: 12px;
  border-top: 3px solid var(--property-color, #5E0D8B);
  border-radius: 4px;

  .mat-form-field {
    width: 100%;
  }
}
// Top Search Filter Box End


// Multi Select with page number Start
.select-multi {
  padding: 7px 10px;
  border: 1px solid #112135 !important;
  border-radius: 4px;
  width: 300px !important;
}
.page-number {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1320px) {
  .page-number {
    flex-direction: column;
  }
}
// Multi Select with page number End

// Table Page Numbering Start
.page-number-bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.page-number-text {
  font-size: small !important;
  color: rgba(0, 0, 0, 0.5411764706) !important;
  float: right;
  margin: 0 !important;
}
// Table Page Numbering End

// Table th CSS Start
th.actionColumn {
  font-weight: 600 !important;
}
// Table th CSS End

// Common QR Code CSS start
.qrcode-box button img {
  background-color:#5E0D8B;
  border-radius: 50px;
}
.print-qr-img{
  background-color: transparent !important;
  border-radius: 50px;
}
// Common QR Code CSS end


//Responsive Table
.view-changes-table,
.view-details {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed; /* Ensures equal width cells */
}
.view-changes-table table {
  width: 100%;
}

// table td {
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }


.color-preview{
  width: 20px; 
  height: 20px; 
  border: 1px solid black;
}

.mat-table td
.mat-table th{
  padding: 5px !important;
}

.mat-option.mat-active{
  color: black !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $bg-color !important;
}

td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type, th.mat-header-cell:first-of-type {
  padding-left: 24px !important;
}

.mat-cell{
  padding: 5px !important; 
}